<template>
  <div class="bar-container">
    <div class="bar-container-item mr65 mr25">
      <img @click="hrefAppLoad('helper')" src="@/accesst/index/help.jpg" alt="" srcset="">
      <div class="n-w">同屏控制</div>
    </div>
    <div class="bar-container-item mr65 mr25">
      <img @click="href('userList')" src="@/accesst/index/manage.png" alt="" srcset="">
      <div class="n-w">用户管理</div>
    </div>
    <div class="bar-container-item mr65 mr25">
      <img @click="href('deviceBind')" src="@/accesst/index/share.png" alt="" srcset="">
      <div class="n-w">设备绑定</div>
    </div>
    <div class="bar-container-item mr25">
      <img @click="href('device')" src="@/accesst/index/setting.png" alt="" srcset="">
      <div class="n-w">设备管理</div>
    </div>
    <div class="bar-container-item mr65">
      <img @click="href('goods')" src="@/accesst/index/jifen.png" alt="" srcset="">
      <div class="n-w">积分兑换</div>
    </div>
    <div class="bar-container-item mr65">
      <img @click="href('user')" src="@/accesst/index/user.png" alt="" srcset="">
      <div class="n-w">个人信息</div>
    </div>
    <div v-show="show" class="bar-container-item">
      <img @click="hrefApp('user')" src="@/accesst/index/zulin.jpg" alt="" srcset="">
      <div class="n-w">租赁管理</div>
    </div>
  </div>
</template>
<script>
import { checkIsBindLeaseDevice } from '@/api/h5';
export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    href(name) {
      this.$router.push({name});
    },
    hrefAppLoad() {
      window.location.href = 'https://s.ououx.com/download.html'
    },
    hrefApp() {
      location.href = 'weixin://dl/business/?appid=wxbaa84f747ce54554&path=pages/goods/lease_order_list/index'
    }
    // 
  },
  created() {
    checkIsBindLeaseDevice({}).then(res => {
      console.log('show', res)
      this.show = res;
    })
  }
}
</script>

<style lang="less" scoped>

.mr65 {
  margin-right: .65rem;
}


.mr25 {
  margin-bottom: .25rem;
}
.n-w {
  white-space:nowrap
}
.bar-container {
  // height: 1.87rem;
  padding: .3rem;
  display: flex;
  // gap: .25rem .65rem;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  .bar-container-item {
    height: 1.27rem;
    width: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #000000;    
    text-align: center;
    img {
      width: .77rem;
      height: .77rem;
      display: block;      
      margin: 0 auto .19rem;
    }
  }
}
</style>